<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { getData, postData, deleteData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { onMount } from "svelte";
  import Line from "svelte-chartjs/src/Line.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
  } from "../../components/DataStore";
  import SvelteTable from "svelte-table";
  import printJS from "print-js";
  import { formatNumberWith4Colors } from "utils/FormatUtils.svelte";
  import TeacherComments from "./TeacherComments.svelte";
  import StudentAccountProgressReport from "./StudentAccountProgressReport.svelte";
  import StudentAccountRecordingPageHeatMap from "./StudentAccountRecordingPageHeatMap.svelte";
  import ChangePassword from "./ChangePassword.svelte";
  import ChangeStudentName from "./ChangeStudentName.svelte";
  import WpsGauge from "./WpsGauge.svelte";
  import StudentAccountStudentRoster from "./StudentAccountStudentRoster.svelte";

  export let studentAccountId;

  let studentDetails = {};
  let studentStatistics = {};

  let studentDetailsStatus = "loading";
  let studentStatisticsStatus = "loading";

  onMount(async () => {
    getStudentDetails();
    getStudentStatistics();
  });

  export let activeTab;

  if (!activeTab) {
    activeTab = "OrganizationTeacherTable";
  }

  function changeTab(page) {
    activeTab = page;
    router.goto(`/student-account-detail/${studentAccountId}/${page}`);
  }

  function getStudentDetails() {
    getData("api/teacher/student-accounts/" + studentAccountId)
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        studentDetails = data;
        studentDetailsStatus = "ok";
        // getStudentRecordingList();
      })
      .catch((error) => {
        studentDetailsStatus = "error";
        console.log("error lessons", error);
      });
  }

  function getStudentStatistics() {
    getData("api/teacher/student-accounts/" + studentAccountId + "/statistics2")
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        studentStatistics = data;
        studentStatisticsStatus = "ok";
        // getStudentRecordingList();
      })
      .catch((error) => {
        // studentDetailsStatus = "error";
        studentStatistics = {};
        studentStatisticsStatus = "NO_DATA";
        console.log("error lessons", error);
      });
  }

  function viewRecordingDetail(r) {
    console.log(
      "viewRecordingDetail",
      r?.courseId,
      r?.lessonId,
      r?.exerciseId,
      r?.id,
    );

    currentCourseId.update((x) => r?.courseId);
    currentCourseName.update((x) => r?.courseName);
    currentLessonId.update((x) => r?.lessonId);
    currentLessonName.update((x) => r?.lessonName);
    currentExerciseId.update((x) => r?.exerciseId);
    currentExerciseName.update((x) => r?.exerciseTitle);
    currentRecordingId.update((x) => r?.id);
    clickChangePage("recording-detail/" + $currentRecordingId);
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    $currentPage = e;
    router.goto("/" + e);
  }

  let filterSelections = {};

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  let studentProgressData;

  function prepareStudentProgressData() {
    studentRecordingList = studentRecordingList.sort((a, b) => {
      return a.id - b.id;
    });

    let dataLabels = studentRecordingList.map((sr) => {
      return new Date(Date.parse(sr.createdAt)).toLocaleDateString();
    });

    let data = studentRecordingList.map((sr) => {
      return Number.parseFloat(sr.overallScore).toFixed(0);
    });

    //data = [1, 2, 3];
    console.log(555, dataLabels);

    studentProgressData = {
      labels: dataLabels,
      datasets: [
        {
          label: studentDetails?.studentName,
          data: data,
          borderColor: "rgb(78, 115, 223)",
          lineTension: 0,
        },
      ],
    };
  }

  function deleteStudentData() {
    // console.log('delete exercise')
    if (
      confirm(
        $_("STUDENT_DETAIL_DELETE_ALL_RECORDINGS_CONFIRM", {
          values: { studentName: studentDetails?.studentName },
        }),
      )
    ) {
      // console.log('Thing was saved to the database.')
      deleteData("api/student/" + studentDetails?.studentName)
        .then((data) => {
          // console.log('All recordings for student removed successfully', data)

          clickChangePage("students");
        })
        .catch((error) => {
          // console.log('error Student delete', error)
        });
    } else {
      // console.log('Thing was not saved to the database.')
    }
  }

  function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds) % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${
      hours > 0 ? formattedHours + ":" : ""
    }${formattedMinutes}:${formattedSeconds}`;
  }
</script>

  {#if studentStatisticsStatus == "NO_DATA"}
    <div class="s-content-spacing" style="margin-top: 0 !important;">
      <div class="card shadow mb-4">
        <div class="card-header py-3 no-print" id="no-print">
          {#if studentDetails?.id}
            <a href="/student-analytics/{studentDetails?.id}"
              ><button
                class="btn btn-primary s-button-primary"
                style="margin-left: 10px"
                >{$_("ANALYTICS_SECTION.ANALYTICS")}</button
              ></a
            >
          {/if}
          <ChangePassword {studentAccountId} />
          <ChangeStudentName
            {studentAccountId}
            {studentDetails}
            on:saved={getStudentDetails}
          />
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">{$_("NO_DATA")}</div>
          </div>
        </div>
      </div>
    </div>
  {:else if studentStatisticsStatus == "ok"}
    <div class="s-content-spacing" style="margin-top: 0 !important;">
      <div class="card shadow mb-4">
        <div class="card-header py-3 no-print" id="no-print">
          <ChangePassword {studentAccountId} />
          <ChangeStudentName
            {studentAccountId}
            {studentDetails}
            on:saved={getStudentDetails}
          />
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-8">
                <div class="mb-1">
                  {$_("AVERAGE_SCORE")}
                  {studentStatistics?.overallScoreAvg.toFixed(0)}
                </div>
                <div class="progress mb-4">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentStatistics?.overallScoreAvg.toFixed(
                      0,
                    )}%"
                    aria-valuenow={studentStatistics?.overallScoreAvg.toFixed(
                      0,
                    )}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div class="mb-1 small">
                  {$_("ACCURACY_SCORE")}
                  {studentStatistics?.accuracyScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentStatistics?.accuracyScoreAvg.toFixed(
                      0,
                    )}%"
                    aria-valuenow={studentStatistics?.accuracyScoreAvg.toFixed(
                      0,
                    )}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>

                <div class="mb-1 small">
                  {$_("COMPLETENESS_SCORE")}
                  {studentStatistics?.completenessScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentStatistics?.completenessScoreAvg.toFixed(
                      0,
                    )}%"
                    aria-valuenow={studentStatistics?.completenessScoreAvg.toFixed(
                      0,
                    )}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>

                <div class="mb-1 small">
                  {$_("FLUENCY_SCORE")}
                  {studentStatistics?.fluencyScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentStatistics?.fluencyScoreAvg.toFixed(
                      0,
                    )}%"
                    aria-valuenow={studentStatistics?.fluencyScoreAvg.toFixed(
                      0,
                    )}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>

                <div class="mb-1 small">
                  {$_("PRONUNCIATION_SCORE")}
                  {studentStatistics?.pronScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentStatistics?.pronScoreAvg.toFixed(0)}%"
                    aria-valuenow={studentStatistics?.pronScoreAvg.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>

              <div class="col-4">
                <div class="row mb-3">
                  <div class="col">
                    <div class="card border-left-secondary shadow h-100 py-2">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div
                              class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                            >
                              {$_("NUMBER_OF_RECORDINGS")}
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                              {studentStatistics?.publicRecordingCount}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i
                              class="fas fa-clipboard-list fa-2x text-gray-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div
                              class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                            >
                              {$_("TOTAL_WORD_COUNT")}
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                              {studentStatistics?.totalWordCount}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-comments fa-2x text-gray-300" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div
                              class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                            >
                              {$_("TOTAL_RECORDING_TIME")}
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                              {formatDuration(
                                studentStatistics?.totalDurationSec,
                              )}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-comments fa-2x text-gray-300" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row mb-3">
                  <div class="col">
                    <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body ">
                        <WpsGauge value={44} />
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  {/if}

  <StudentAccountRecordingPageHeatMap {studentAccountId} />

  <!-- <TeacherComments {studentAccountId} /> -->

<style>
  :global(.recordingRow) {
    cursor: pointer;
  }

  :global(.recordingRow:hover) {
    background-color: whitesmoke;
  }

  .table-responsive {
    overflow: hidden;
  }

  .strong {
    font-weight: 700;
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid #00a094 !important;
    border-bottom-width: 0px !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-item {
    margin-right: 1rem;
    cursor: pointer;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
  }
  .nav {
    border-bottom: 4px solid #00a094;
    color: black;
  }

  .nav-link {
    color: #575f59;
  }

  .nav-link.active {
    background-color: #00a094;
    color: white;
  }
</style>
