<script>
    import { _ } from "svelte-i18n";

    import Loader from "../../components/Loader.svelte";
    import { onMount } from "svelte";
    import { getData, deleteData } from "../../utils/ApiUtils.svelte";
    import TableHeaderItem from "../../components/TableHeaderItem.svelte";
    import { userConfig } from "../../components/DataStore";
    import { dynamicSort } from "utils/SortingUtils.svelte";

    export let studentAccountId;

    let dataList = [];

    let dataStatus = "loading";

    let sortColumn = "courseName";
    let sortDirection = 1;

    onMount(async () => {
        getStudentAccountCourses();
    });

    function getStudentAccountCourses() {
        dataStatus = 'loading'
        getData(`api/teacher/student-accounts/${studentAccountId}/courses`)
            .then((data) => {
                dataList = data;
                dataList = dataList.sort(dynamicSort(sortColumn, sortDirection));
                dataStatus = "ok";
            })
            .catch((error) => {
                dataStatus = "error";
                // console.log('error lessons', error)
            });
    }

    function changeSort(e) {
        console.log(111, e.detail);
        sortColumn = e.detail.sortColumn;
        sortDirection = e.detail.sortDirection;

        // getStudentList();

        dataStatus = "loading";

        dataList = dataList.sort(dynamicSort(sortColumn, sortDirection));

        dataStatus = "ok";
    }

    function removeStudent(v) {
        if (
            confirm(
                $_("REMOVE_STUDENT_FROM_CLASS_DIALOG", {
                    values: {
                        studentName: v?.login,
                        className: v?.courseName,
                    },
                }),
            )
        ) {
            deleteData(
                "api/courses/" +
                    v?.courseId +
                    "/student-accounts/" +
                    v?.id,
            )
                .then((data) => {
                    getStudentAccountCourses();
                })
                .catch((error) => {
                    console.log("error courses", error);
                });
        } else {
            console.log("Thing was not saved to the database.");
        }
    }
</script>

<div class="s-content-spacing" style="margin-top: 0 !important;">
    <div class="card shadow">
        <div class="card-body">
            {#if dataStatus === "loading"}
                <Loader />
            {:else if dataStatus === "error"}
                <div class="alert alert-danger" role="alert">
                    {$_("LESSON_LIST_LOAD_ERROR")}
                </div>
            {:else}
                <div
                    class="table-responsive table mt-2"
                    style="overflow-y: hidden"
                    id="dataTable"
                    role="grid"
                    aria-describedby="dataTable_info"
                >
                    <table class="table my-0 table-hover" id="dataTable">
                        <thead>
                            <th>#</th>
                            <th
                                ><TableHeaderItem
                                    column="courseName"
                                    {sortColumn}
                                    {sortDirection}
                                    on:changeSort={changeSort}
                                    >{$_("COURSE_NAME")}</TableHeaderItem
                                ></th
                            >
                            <th
                                ><TableHeaderItem
                                    column="teacherName"
                                    {sortColumn}
                                    {sortDirection}
                                    on:changeSort={changeSort}
                                    >{$_("TEACHER")}</TableHeaderItem
                                ></th
                            >
                            <th
                                ><TableHeaderItem
                                    column="createdAt"
                                    {sortColumn}
                                    {sortDirection}
                                    on:changeSort={changeSort}
                                    >{$_("CREATED_AT")}</TableHeaderItem
                                ></th
                            >
                            <th>{$_("STUDENT_ROSTER.ACTION")}</th>
                        </thead>
                        <tbody>
                            {#each dataList as v, i}
                                <tr>
                                    <td
                                        >{i + 1}
                                        <span class="ms-2">
                                            <a
                                                href={`/#/courses/${v.courseId}`}
                                                target="_blank"
                                                ><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 30 30"
                                                    width="1em"
                                                    height="1em"
                                                    ><path
                                                        d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"
                                                    /></svg
                                                ></a
                                            ></span
                                        ></td
                                    >
                                    <td>
                                        <a
                                            href={`/#/courses-detail/${v.courseId}`}
                                            ><strong>{v.courseName}</strong></a
                                        >
                                    </td>
                                    <td>
                                        {v.teacherName}
                                    </td>
                                    <td
                                        >{new Date(
                                            Date.parse(v?.createdAt + "Z"),
                                        ).toLocaleString()}</td
                                    >

                                    <td
                                        >{#if $userConfig?.serverFlags?.studentManagement !== "R"}<button
                                                class="btn btn-danger s-button-danger"
                                                on:click={(x) => {
                                                    removeStudent(v);
                                                }}
                                                ><i
                                                    class="far fa-window-close"
                                                    style="padding-right: 8px;"
                                                />{$_("DELETE")}</button
                                            >{/if}</td
                                    >
                                </tr>
                            {:else}{$_("NO_DATA")}
                            {/each}
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col-md-6 align-self-center">
                        <p
                            id="dataTable_info"
                            class="dataTables_info"
                            role="status"
                            aria-live="polite"
                        >
                            {dataList?.length}
                            {$_("RESULT_COUNT")}
                        </p>
                    </div>
                    <div class="col-md-6">
                        <nav
                            class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
                        />
                    </div>
                </div>
            {/if}
        </div>
    </div>
</div>

<style>
    .result {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
    }

    .result .result-good {
        background-color: green;
        color: aliceblue;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .result-bad {
        background-color: red;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .no-result {
        background-color: grey;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    /* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
} */
</style>
