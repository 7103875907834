<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { getData, putData, deleteData } from "utils/ApiUtils.svelte";
  import { createEventDispatcher } from "svelte";
  import Loader from "components/Loader.svelte";
  import {
    currentPage,
    userConfig,
    userDetails,
  } from "../../../components/DataStore";
  export let courseId;

  const dispatch = createEventDispatcher();

  let listOfStudentRoster = [];
  let listOfStudentRosterValue = undefined;
  let studentDataStatus = "new";
  let importStatusMessage;

  onMount(async () => {
    loadStudentRosterList();
  });

  function loadStudentRosterList() {
    studentDataStatus = "loading";
    getData("api/student-roster")
      .then((data) => {
        console.log("courses - student-accounts successful", data);

        if ($userDetails?.organizationAdminUserId !== $userDetails?.id && $userDetails?.dsns) {
          data = data.filter((d) => {
            return d.userId === $userDetails?.id;
          });
        }

        listOfStudentRoster = data;
        studentDataStatus = "ok";
      })
      .catch((error) => {
        console.log("error student accounts", error);
        studentDataStatus = "error";
      });
  }

  function importStudents() {
    putData(
      `api/courses/${courseId}/student-roster/${listOfStudentRosterValue}`,
      {},
      false,
    )
      .then((data) => {
        console.log("courses - student-accounts successful", data);

        dispatch("studentImportDone", {});
      })
      .catch((error) => {
        console.log("error student accounts", error);
        studentDataStatus = "error";
        importStatusMessage = error?.message;
      });
  }

  function cancelStudentRoster() {
    dispatch("cancelStudentRoster", {});
  }
</script>

<div>
  {#if studentDataStatus === "loading"}
    <Loader />
  {:else if studentDataStatus === "ok"}
    <label for="classRoster">{$_("STUDENT_ROSTER.SELECT_STUDENT_ROSTER")}</label
    >
    <select
      id="classRoster"
      class="form-select"
      bind:value={listOfStudentRosterValue}
    >
      {#if $userDetails?.organizationAdminUserId !== $userDetails?.id}
        {#each listOfStudentRoster as sr}
          {#if $userDetails?.dsns}
            {#if sr.userLogin === $userDetails?.login}
              <option value={sr.id}
                >{#if sr.code}{sr.code} -
                {/if}{sr.name} ({sr.studentAccountCount} + {sr?.studentInvitationCount})</option
              >
            {/if}
          {:else}
            <option value={sr.id}
              >{#if sr.code}{sr.code} -
              {/if}{sr.name} ({sr.studentAccountCount} + {sr?.studentInvitationCount})</option
            >
          {/if}
        {/each}
      {:else}
        {#each listOfStudentRoster as sr}
          {#if sr.userLogin === $userDetails?.login}
            <option value={sr.id}
              >{#if sr.code}{sr.code} -
              {/if}{sr.name} ({sr.studentAccountCount} + {sr?.studentInvitationCount})</option
            >
          {:else}
            <option value={sr.id}
              >{#if sr.code}{sr.code} -
              {/if}{sr.name} ({sr.studentAccountCount} + {sr?.studentInvitationCount})
              ({sr.userFullName} - {sr.userLogin})</option
            >
          {/if}
        {/each}
      {/if}
    </select>
  {:else if studentDataStatus === "error"}
    <div>
      <div class="alert alert-danger">
        {#if importStatusMessage === "STUDENT_ACCOUNT_LIMIT_REACHED"}
          {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_LIMIT_REACHED")}
        {:else if importStatusMessage === "STUDENT_ACCOUNT_ALREADY_ADDED"}
          {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_ALREADY_ADDED")}
        {:else}
          {$_("STUDENT_ACCOUNT.IMPORT_ERROR")}
        {/if}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
          type="button"
          class="close"
          style="float:right; padding:1em;"
          on:click={() => {
            studentDataStatus = "ok";
          }}
        >
          <span>&times;</span>
        </span>
      </div>
    </div>
  {/if}
</div>
<div class="form-row mt-3">
  <button
    class="btn btn-secondary s-button-secondary s-mr-10px"
    on:click={cancelStudentRoster}
  >
    <i class="far fa-window-close" style="padding-right: 8px;" />{$_("CANCEL")}
  </button>

  <button
    class="btn btn-primary s-button-primary"
    disabled={studentDataStatus === "saving" || !listOfStudentRosterValue}
    on:click={importStudents}
  >
    <i class="fa fa-save" style="padding-right: 8px;" />{$_("IMPORT_STUDENTS")}
    <br />
  </button>
</div>
