<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { getData, postData, deleteData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { onMount } from "svelte";
  import Line from "svelte-chartjs/src/Line.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
  } from "../../components/DataStore";
  import StudentAccountProgressReport from "./StudentAccountProgressReport.svelte";
  import StudentAccountStudentRoster from "./StudentAccountStudentRoster.svelte";
  import StudentAccountDetailOverview from "./StudentAccountDetailOverview.svelte";
  import AnalyticsComponent from "../../components/AnalyticsComponent.svelte";
  import StudentAccountDetailRecordings from "./StudentAccountDetailRecordings.svelte";
    import StudentAccountStudentCourse from "./StudentAccountStudentCourse.svelte";

  export let studentAccountId;

  let studentDetails = {};
  let studentStatistics = {};

  let studentDetailsStatus = "loading";
  let studentStatisticsStatus = "loading";

  let studentStatisticsStudentName

  onMount(async () => {
    getStudentDetails();
    getStudentStatistics();
  });

  export let activeTab;

  if (!activeTab) {
    activeTab = "OrganizationTeacherTable";
  }

  function changeTab(page) {
    activeTab = page;
    router.goto(`/student-account-detail/${studentAccountId}/${page}`);
  }

  function getStudentDetails() {
    getData("api/teacher/student-accounts/" + studentAccountId)
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        studentDetails = data;
        studentDetailsStatus = "ok";
        // getStudentRecordingList();
      })
      .catch((error) => {
        studentDetailsStatus = "error";
        console.log("error lessons", error);
      });
  }

  function getStudentStatistics() {
    getData("api/teacher/student-accounts/" + studentAccountId + "/statistics2")
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        studentStatistics = data;
        studentStatisticsStatus = "ok";
        studentStatisticsStudentName = studentStatistics?.studentName
        // getStudentRecordingList();
      })
      .catch((error) => {
        // studentDetailsStatus = "error";
        studentStatistics = {};
        studentStatisticsStatus = "NO_DATA";
        console.log("error lessons", error);
      });
  }

  function viewRecordingDetail(r) {
    console.log(
      "viewRecordingDetail",
      r?.courseId,
      r?.lessonId,
      r?.exerciseId,
      r?.id,
    );

    currentCourseId.update((x) => r?.courseId);
    currentCourseName.update((x) => r?.courseName);
    currentLessonId.update((x) => r?.lessonId);
    currentLessonName.update((x) => r?.lessonName);
    currentExerciseId.update((x) => r?.exerciseId);
    currentExerciseName.update((x) => r?.exerciseTitle);
    currentRecordingId.update((x) => r?.id);
    clickChangePage("recording-detail/" + $currentRecordingId);
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    $currentPage = e;
    router.goto("/" + e);
  }
</script>

<main id="student-detail-report" class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        {$_("STUDENT_REPORT")}
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <div class="card shadow">
      <div class="card-body">
        <h4 class="text-dark mb-4">
          {#if studentDetails?.firstName || studentDetails?.lastName}
            {studentDetails?.firstName} {studentDetails?.lastName}
          {/if}
          {#if studentDetails?.login}({studentDetails?.login}){/if}
        </h4>
      </div>
    </div>
  </div>

  <div class="s-content-spacing" style="margin-bottom: 0 !important;">
    <ul class="nav nav-tabs">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li class="nav-item" on:click={(e) => changeTab("SAOverview")}>
        <span class="nav-link" class:active={activeTab === "SAOverview"}
          >{$_("STUDENT_ACCOUNT_DETAIL.OVERVIEW")}</span
        >
      </li>

      <li class="nav-item" on:click={(e) => changeTab("SACourses")}>
        <span
          class="nav-link"
          class:active={activeTab === "SACourses"}
          on:click={() => {
            activeTab === "SACourses";
          }}
        >
          {$_("STUDENT_ACCOUNT_DETAIL.COURSES")}</span
        >
      </li>

      <li class="nav-item" on:click={(e) => changeTab("SARosters")}>
        <span
          class="nav-link"
          class:active={activeTab === "SARosters"}
          on:click={() => {
            activeTab === "SARosters";
          }}
        >
          {$_("STUDENT_ACCOUNT_DETAIL.ROSTERS")}</span
        >
      </li>

      <li class="nav-item" on:click={(e) => changeTab("SARecordings")}>
        <span
          class="nav-link"
          class:active={activeTab === "SARecordings"}
          on:click={() => {
            activeTab === "SARecordings";
          }}
        >
          {$_("STUDENT_ACCOUNT_DETAIL.RECORDINGS")}</span
        >
      </li>

      <li class="nav-item" on:click={(e) => changeTab("SAReport")}>
        <span
          class="nav-link"
          class:active={activeTab === "SAReport"}
          on:click={() => {
            activeTab === "SAReport";
          }}
        >
          {$_("STUDENT_ACCOUNT_DETAIL.REPORT")}</span
        >
      </li>

      <li class="nav-item" on:click={(e) => changeTab("SAAnalytics")}>
        <span
          class="nav-link"
          class:active={activeTab === "SAAnalytics"}
          on:click={() => {
            activeTab === "SAAnalytics";
          }}
        >
          {$_("STUDENT_ACCOUNT_DETAIL.ANALYTICS")}</span
        >
      </li>
    </ul>
  </div>

  {#if studentAccountId}
    <div class="" style="margin-top: 0 !important;">
      {#key activeTab}
        {#if activeTab === "SAOverview"}
          <StudentAccountDetailOverview {studentAccountId} />
          {:else if activeTab === "SACourses"}
          <StudentAccountStudentCourse {studentAccountId} />
          {:else if activeTab === "SARosters"}
          <StudentAccountStudentRoster {studentAccountId} />
        {:else if activeTab === "SAAnalytics"}
          <div class="s-content-spacing" style="margin-top: 0 !important;">
            <AnalyticsComponent {studentAccountId} />
          </div>
        {:else if activeTab === "SARecordings"}
          {#if studentStatisticsStudentName}
            <StudentAccountDetailRecordings
              {studentAccountId}
              studentName={studentStatisticsStudentName}
            ></StudentAccountDetailRecordings>
          {/if}
        {:else if activeTab === "SAReport"}
          <StudentAccountProgressReport {studentAccountId} />
        {/if}
      {/key}
    </div>
  {/if}

  <!-- <TeacherComments {studentAccountId} /> -->
</main>

<style>
  :global(.recordingRow) {
    cursor: pointer;
  }

  :global(.recordingRow:hover) {
    background-color: whitesmoke;
  }

  .table-responsive {
    overflow: hidden;
  }

  .strong {
    font-weight: 700;
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid #00a094 !important;
    border-bottom-width: 0px !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-item {
    margin-right: 1rem;
    cursor: pointer;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
  }
  .nav {
    border-bottom: 4px solid #00a094;
    color: black;
  }

  .nav-link {
    color: #575f59;
  }

  .nav-link.active {
    background-color: #00a094;
    color: white;
  }
</style>
