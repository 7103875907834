<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, postData, deleteData } from "utils/ApiUtils.svelte";
  import Breadcrumb from "components/Breadcrumb.svelte";
  import ShareExercise from "../../Exercise/ExerciseComponent/ShareExercise.svelte";
  import ExerciseTable from "../../Exercise/ExerciseComponent/ExerciseTable.svelte";
  import StudentLessonProgressTable from "./StudentLessonProgressTable.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    userDetails,
    userConfig,
    currentCourseRoleCheck,
  } from "../../../components/DataStore";
  import LessonDetailDropdown from "./LessonDetailDropdown.svelte";
  import DialogCopyLessonToOtherTeacher from "./DialogCopyLessonToOtherTeacher.svelte";

  function formatIsoStringToYYYYMMDDHHSSLocale(dateString) {
    return new Date(Date.parse(dateString)).toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  let lessonData = { id: 123, name: "cousr 123", description: "xxxx" };
  let exerciseList = [];

  onMount(async () => {
    getLesson($currentCourseId, $currentLessonId);
  });

  let lessonDetailStatus = "loading";

  function editLessonDetail() {
    console.log("editLessonDetail", $currentCourseId, $currentLessonId);
    //clickChangePage("lesson-edit");
    clickChangePage(
      "courses/" + $currentCourseId + "/lesson-edit/" + $currentLessonId,
    );
  }

  function clickChangePage(e) {
    // console.log('clickChangePage', e)
    $currentPage = e;
    router.goto("/" + e);
  }

  function getLesson(courseId, lessonId) {
    lessonDetailStatus = "loading";
    getData("api/courses/" + courseId + "/lessons/" + lessonId)
      .then((data) => {
        console.log("lessons successful", data);
        lessonData = data;
        currentCourseName.update((x) => lessonData?.courseName);
        currentLessonName.update((x) => lessonData?.name);
        lessonDetailStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
      });

    getData(`api/courses/${$currentCourseId}/user-roles/check`)
      .then((data) => {
        console.log("courses successful", data);
        $currentCourseRoleCheck = data;
        // currentCourseName.update((x) => courseData?.name)
        // courseDetailStatus = 'ok'
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });

    getData("api/courses")
      .then((data) => {
        targetCourseList = data
          .filter((v) => {
            return v.id != $currentCourseId;
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      })
      .catch((error) => {
        console.log("error courseList", error);
      });
  }

  function isLessonActive(lessonData) {
    if (lessonData?.activeDateStart || lessonData?.activeDateEnd) {
      let startDate = Date.parse(lessonData?.activeDateStart);
      let endDate = Date.parse(lessonData?.activeDateEnd);
      let now = Date.now();

      console.log(333, startDate, endDate, now);
      if (startDate && startDate > now) {
        return false;
      }
      if (endDate && endDate < now) {
        return false;
      }
      return true;
    }
    return null;
  }

  let targetCourseId;
  let copyLessonToOtherCourse = false;
  let targetCourseList = [];
  let copyLessonToOtherCourseSuccessStatus = "new";

  function copyCourseToOtherCourse() {
    copyLessonToOtherCourse = !copyLessonToOtherCourse;
  }

  function copyLessonToOtherCourseConfirm() {
    let payload = {
      courseId: $currentCourseId,
      lessonId: $currentLessonId,
      targetCourseId: targetCourseId,
    };

    copyLessonToOtherCourseSuccessStatus = "saving";

    postData(
      `api/courses/${$currentCourseId}/lessons/${$currentLessonId}/copyToCourse`,
      payload,
    )
      .then((data) => {
        console.log("courses successful", data);
        copyLessonToOtherCourseSuccessStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
        copyLessonToOtherCourseSuccessStatus = "error";
      });
  }

  function copyLessonToOtherCourseCancel() {
    copyLessonToOtherCourse = false;
  }

  function duplicateLesson(){
    let payload = {
      courseId: $currentCourseId,
      lessonId: $currentLessonId,
      targetCourseId: $currentCourseId,
    };

    copyLessonToOtherCourseSuccessStatus = "saving";

    postData(
      `api/courses/${$currentCourseId}/lessons/${$currentLessonId}/copyToCourse`,
      payload,
    )
      .then((data) => {
        console.log("courses successful", data);
        copyLessonToOtherCourseSuccessStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
        copyLessonToOtherCourseSuccessStatus = "error";
      });
  }

  function deleteLesson() {
    console.log("delete lesson");
    mixpanel.track("Teacher Delete Button Clicked", {
      "Button Location": "Lesson Edit Page",
    });

    if (
      confirm(
        $_("LESSON_DELETE_CONFIRM", {
          values: { lessonName: lessonData?.name },
        }),
      )
    ) {
      // Save it!
      console.log("Thing was saved to the database.");
      deleteData(
        "api/courses/" + $currentCourseId + "/lessons/" + $currentLessonId,
      )
        .then((data) => {
          console.log("lesson deleted successfully", data);

          clickChangePage("courses-detail/" + $currentCourseId);
        })
        .catch((error) => {
          console.log("error lesson", error);
        });
    } else {
      console.log("Thing was not saved to the database.");
    }
  }

  let dialogCopyToOtherTeacherDialog;
  function openCopyToOtherTeacherDialog() {
    dialogCopyToOtherTeacherDialog.openCopyToOtherTeacherDialog();
  }
</script>

<DialogCopyLessonToOtherTeacher
  bind:this={dialogCopyToOtherTeacherDialog}
  on:done={(e) => {
    copyLessonToOtherCourseSuccessStatus = "ok";
  }}
  {$currentLessonId}
></DialogCopyLessonToOtherTeacher>

{#if lessonDetailStatus === "loading"}
  <Loader />
{:else}
  <div class="card shadow">
    <div class="card-body">
      <div>
        <div class="row">
          <div class="col offset-lg-0">
            <h3>{lessonData.name}</h3>
          </div>
          <div class="col">
            <LessonDetailDropdown
              on:refresh={() => {
                getLesson($currentCourseId, $currentLessonId);
              }}
              on:editLessonDetail={editLessonDetail}
              on:copyLesson={copyCourseToOtherCourse}
              on:copyLessonToOtherTeacher={openCopyToOtherTeacherDialog}
              on:deleteLesson={deleteLesson}
              on:duplicateLesson={duplicateLesson}
            />
          </div>
        </div>
        <form>
          <div class="form-row">
            <div class="col-lg-12 offset-lg-0">
              <pre
                style="word-wrap: break-word; white-space: pre-wrap;">{#if lessonData?.description}{lessonData?.description}{/if}</pre>
            </div>
          </div>
          {#if lessonData?.activeDateStart || lessonData?.activeDateEnd}
            <div class="row">
              <div class="col-sm">
                {$_("AVAILABLE_FROM")}
                {#if lessonData?.activeDateStart}{formatIsoStringToYYYYMMDDHHSSLocale(
                    lessonData?.activeDateStart,
                  )}{/if}
                {$_("AVAILABLE_UNTIL")}
                {#if lessonData?.activeDateEnd}{formatIsoStringToYYYYMMDDHHSSLocale(
                    lessonData?.activeDateEnd,
                  )}{/if}
                {#if lessonData?.activeDateStart || lessonData?.activeDateEnd}
                  {#if isLessonActive(lessonData)}
                    <span style="color:green">&#9679;</span>
                  {:else}
                    <span style="color:red">&#9679;</span>
                  {/if}
                {/if}
              </div>
            </div>
          {/if}
        </form>
      </div>

      {#if copyLessonToOtherCourse}
        <div class="row align-items-end mb-2">
          <div class="col-6" />
          <div class="col-sm">
            <label for="copyCourseToEmail">{$_("SELECT_COURSE")}</label>
            <div class="form-group">
              {#if targetCourseList && targetCourseList.length > 0}
                <select
                  class="form-select"
                  bind:value={targetCourseId}
                  aria-label="multiple select example"
                >
                  {#each targetCourseList as c}
                    <option value={c.id}>{c.name}</option>
                  {/each}
                </select>
                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  on:click={copyLessonToOtherCourseCancel}
                  >{$_("CANCEL")}</button
                >

                <button
                  class="btn btn-primary s-button-primary"
                  on:click={copyLessonToOtherCourseConfirm}
                  >{$_("CONFIRM")}</button
                >
              {:else}
                <div class="mb-2">{$_("LESSON_NO_OTHER_CLASS_CANT_COPY")}</div>
                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  on:click={copyLessonToOtherCourseCancel}
                  >{$_("CANCEL")}</button
                >
              {/if}
            </div>
          </div>
        </div>
      {/if}

      {#if copyLessonToOtherCourseSuccessStatus === "error"}
        <div class="alert alert-danger" role="alert">
          {$_("LESSON_SAVING_ERROR")}
        </div>
      {:else if copyLessonToOtherCourseSuccessStatus === "saving"}
        <div class="alert alert-info" role="alert">
          {$_("PROCESSING")}
        </div>
      {:else if copyLessonToOtherCourseSuccessStatus === "ok"}
        <div class="alert alert-success" role="alert">
          {$_("COPIED")}
        </div>
      {/if}

      <div class="float-end" role="group">
        {#if $userDetails?.userRole === "ADMIN" || $userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.copyClassToOrganizations === true}
          <button
            on:click={openCopyToOtherTeacherDialog}
            class="btn btn-secondary s-button-secondary s-mr-10px"
            ><i class="far fa-copy" style="padding-right: 8px;" />{$_(
              "LESSON.COPY_TO_OTHER_TEACHER",
            )}</button
          >
        {/if}

        {#if targetCourseList && targetCourseList.length > 0}
          <button
            class="btn btn-secondary s-button-secondary s-mr-10px"
            on:click={copyCourseToOtherCourse}
          >
            <i class="far fa-copy" style="padding-right: 8px;" />{$_(
              "COPY_TO_OTHER_COURSE",
            )}</button
          >
        {/if}

        <button
          class="btn btn-secondary s-button-secondary"
          disabled={"RW" !== $currentCourseRoleCheck?.permissions}
          on:click={editLessonDetail}
        >
          <i class="fa fa-edit" style="padding-right: 8px;" />{$_(
            "EDIT_LESSON_INFO",
          )}</button
        >

        {#if $userConfig?.serverFlags?.disablePinSharing === false || !($userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.disablePinSharing === true)}
          <ShareExercise
            pin={lessonData?.key}
            asButton
            shareType="LESSON"
            id={lessonData?.key}
            isActive={isLessonActive(lessonData)}
          />
        {/if}
      </div>
    </div>
  </div>
{/if}

<style>
</style>
