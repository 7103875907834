<script>
  import { _ } from "svelte-i18n";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, putData, deleteData } from "utils/ApiUtils.svelte";
  import { CSVToArray, arrayIsEmpty } from "utils/FormatUtils.svelte";
  import StudentEnrollmentDropdown from "./StudentEnrollmentDropdown.svelte";
  import { dynamicSort } from "utils/SortingUtils.svelte";
  import { generateUUID } from "utils/FormatUtils.svelte";
  import {
    currentCourseId,
    isOpenSendFeedbackModal,
    userConfig,
    userDetails,
    currentCourseRoleCheck,
  } from "../../../components/DataStore";
  import StudentAccountAddFromRoster from "./StudentAccountAddFromRoster.svelte";
  import StudentRosterFromCourse from "./StudentRosterFromCourse.svelte";
  import DialogInviteStudentsToClassByCode from "./DialogInviteStudentsToClassByCode.svelte";

  export let courseId;
  export let courseName;

  let studentData = [];
  let studentDataStatus = "loading";
  let addStudentFlag = false;
  let uploadStudentCsvFlag = false;
  let addStudentRosterFlag = false;
  let createStudentRosterFlag = false;
  let newStudent = { studentEmail: "" };

  let isValidEmail = true;
  let dropArea = undefined;
  let dropAreaHighlighted = false;
  let importStatus = undefined;
  let importStatusMessage = undefined;

  let csvTable = [];

  let sortColumn = "idStudent";
  let sortDirection = 1;

  let dialogInviteStudentsToClassByCode;

  function changeSort(c, d) {
    sortColumn = c;
    sortDirection = d;

    studentDataStatus = "loading";

    studentData = studentData.sort(dynamicSort(c, d));

    studentDataStatus = "ok";
  }

  onMount(async () => {
    console.log("studentData", studentData);
    studentDataStatus = "loading";
    loadStudents();
  });

  function loadStudents() {
    studentDataStatus = "loading";
    getData("api/courses/" + courseId + "/student-accounts")
      .then((data) => {
        console.log("courses - student-accounts successful", data);

        data.map((s) => {
          if (s?.state === "PENDING") {
            s.email = s?.pendingInvitationEmail;
          } else {
            s.email = s?.studentLogin;
          }

          if (s?.studentFirstName || s?.studentLastName) {
            s.fullName = s?.studentFirstName + " " + s?.studentLastName;
          } else {
            s.fullName = "";
          }
        });

        studentData = data;
        studentDataStatus = "ok";
      })
      .catch((error) => {
        console.log("error student accounts", error);
        studentDataStatus = "error";
      });
  }

  function addNewStudent() {
    mixpanel.track("Teacher Add New Student Account Button Clicked");
    addStudentFlag = true;
    uploadStudentCsvFlag = false;
    importStatus = undefined;
    isValidEmail = true;
    addStudentRosterFlag = false;
    createStudentRosterFlag = false;
  }

  function uploadStudentCsv() {
    mixpanel.track("Teacher Add New Student Account CSV Upload Button Clicked");
    addStudentFlag = false;
    uploadStudentCsvFlag = true;
    importStatus = undefined;
    isValidEmail = true;
    addStudentRosterFlag = false;
    createStudentRosterFlag = false;
  }

  function addStudentRoster() {
    mixpanel.track(
      "Teacher Add New Student Account From Roster Button Clicked",
    );
    addStudentFlag = false;
    uploadStudentCsvFlag = false;
    importStatus = undefined;
    isValidEmail = true;
    addStudentRosterFlag = true;
    createStudentRosterFlag = false;
  }

  function createStudentRoster() {
    addStudentFlag = false;
    uploadStudentCsvFlag = false;
    importStatus = undefined;
    isValidEmail = true;
    addStudentRosterFlag = false;
    createStudentRosterFlag = true;
  }

  function cancelNewStudentEdit() {
    addStudentFlag = false;
    uploadStudentCsvFlag = false;
    csvTable = [];
  }

  function cancelStudentRoster() {
    addStudentFlag = false;
    uploadStudentCsvFlag = false;
    addStudentRosterFlag = false;
    csvTable = [];
  }

  function cancelCreateStudentRoster() {
    createStudentRosterFlag = false;
  }

  function addNewStudentSave() {
    let emails = [];
    importStatus = undefined;

    if (newStudent?.studentEmail.includes(",")) {
      emails = newStudent?.studentEmail.split(",");
      emails = emails.map((element) => element.trim());
      console.log(888, emails);
    } else if (newStudent?.studentEmail.includes(" ")) {
      emails = newStudent?.studentEmail.split(" ");
      emails = emails.map((element) => element.trim());
      console.log(889, emails);
    } else {
      console.log(777, newStudent?.studentEmail);
      emails = [newStudent?.studentEmail];
    }

    emails.forEach((e) => {
      isValidEmail = validateEmail(e);
    });

    if (!isValidEmail) {
      return;
    }

    if (
      studentData.filter(
        (s) => s.pendingInvitationEmail === newStudent?.studentEmail,
      ).length > 0
    ) {
      importStatus = "error";
      importStatusMessage = "STUDENT_ACCOUNT_ALREADY_ADDED";
      return;
    }

    Promise.all(emails.map((e) => addNewStudentMultiple(e)))
      .then(() => {
        console.log(111, "then");
        importStatus = "ok";
      })
      .finally((data) => {
        console.log(111, "finally");
        studentDataStatus = "loading";
        addStudentFlag = false;
        loadStudents();
        newStudent = {};
      })
      .catch((error) => {
        console.log(111, "error");
        console.log(555, "error");
      });
  }

  function addNewStudentMultiple(studentEmail) {
    let payloadData = {
      courseId: courseId,
      studentEmail: studentEmail,
    };

    isValidEmail = validateEmail(studentEmail);

    if (!isValidEmail) {
      return;
    }

    if (
      studentData.filter((s) => s.pendingInvitationEmail === studentEmail)
        .length > 0
    ) {
      importStatus = "error";
      importStatusMessage = "STUDENT_ACCOUNT_ALREADY_ADDED";
      return;
    }

    console.log("save course", payloadData);

    return putData(
      "api/courses/" + courseId + "/student-accounts",
      payloadData,
      false,
    )
      .then((data) => {})
      .catch((error) => {
        console.log("error import student-accounts", error);
        importStatus = "error";
        importStatusMessage = error?.message;
        throw new Error(importStatus, importStatusMessage);
      });
  }

  function addNewStudentUploadCsv() {
    studentDataStatus = "loading";
    Promise.all(
      csvTable
        .filter((v) => validateEmail(v[0]))
        .map((e) => addNewStudentMultiple(e[0])),
    )
      .finally((data) => {
        studentDataStatus = "loading";
        addStudentFlag = false;
        loadStudents();
        newStudent = {};
        if (importStatus != "error") {
          importStatus = "ok";
        }
      })
      .catch((error) => {
        console.log(555, "error");
        importStatusMessage = "CSV_IMPORT_ERROR";
        loadStudents();
      });

    //FIXME - change to Promise.all

    csvTable = [];
    uploadStudentCsvFlag = false;
  }

  function removeStudent(studentAccountEnrollmentId, studentName) {
    mixpanel.track("Teacher Delete Button Clicked", {
      "Button Location": "Course Detail Page Student Table",
    });
    if (
      confirm(
        $_("REMOVE_STUDENT_FROM_CLASS_DIALOG", {
          values: {
            studentName: studentName,
            className: courseName,
          },
        }),
      )
    ) {
      deleteData(
        "api/courses/" +
          courseId +
          "/student-accounts/" +
          studentAccountEnrollmentId,
      )
        .then((data) => {
          loadStudents();
          studentDataStatus = "loading";
        })
        .catch((error) => {
          console.log("error courses", error);
        });
    } else {
      console.log("Thing was not saved to the database.");
    }
  }

  function skipEnterOnInput(event) {
    console.log(event);
    if (event.key === "Enter" && !event.ctrlKey) {
      event.preventDefault();
    } else if (event.key === "Enter" && event.ctrlKey) {
      console.log("Save");
      addNewStudentSave();
    }
  }

  function validateEmail(value) {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }

  function highlight(e) {
    console.log(333, e);
    dropAreaHighlighted = true;
    dropArea.classList.add("highlight");
  }

  function unhighlight(e) {
    console.log(334, e);
    dropAreaHighlighted = false;
    dropArea.classList.remove("active");
  }

  function handleDrop(e) {
    console.log(335, e);
    var dt = e.dataTransfer;
    var files = dt.files;

    handleFiles(files);
  }

  function handleFiles(files) {
    files = [...files];
    files.forEach(previewFile);
  }

  function previewFile(file) {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = function () {
      let csvContent = reader.result;

      csvTable = CSVToArray(csvContent);
      if (csvTable?.length > 1) {
        if (!validateEmail(csvTable[0][0])) {
          csvTable.shift();
        }
      }
      console.log(444, csvContent, csvTable);
    };
  }

  function exportToCsv() {
    const mimeType = "data:text/csv;charset=utf-8";

    const studentDataOut = studentData.map((r) => {
      console.log(777, r);
      let x = {
        studentName:
          r?.state === "PENDING"
            ? ""
            : `${r?.studentFirstName} ${r?.studentLastName}`,
        status: r?.state,
        studentEmail:
          r?.state === "PENDING" ? r?.pendingInvitationEmail : r?.studentLogin,
      };
      return x;
    });

    let items = studentDataOut.map(function (v) {
      let x = Object.assign({}, v);
      delete x.studentToken;
      return x;
    });

    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    //const header = Object.keys(items[0]);
    const header = ["studentEmail", "studentName", "status"];
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(","),
      ),
    ].join("\r\n");

    const d = new Date();

    var dummy = document.createElement("a");
    dummy.href = URL.createObjectURL(
      new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
        type: mimeType,
      }),
    );
    dummy.download = "studentEnrollment" + "-" + d.toISOString() + ".csv";
    dummy.click();
  }

  function openFeedback() {
    mixpanel.track("Need Help on Invite Student Account Clicked");
    $isOpenSendFeedbackModal = true;
  }

  let selectActive = false;
  let selectionSet = new Set();

  function changeSelectCheckbox() {
    if (selectActive) {
      selectNone();
    } else {
      selectActive = true;
      document.getElementById("flexCheckDefault").indeterminate = true;
    }
  }

  function changeSelect(vId) {
    if (selectionSet.has(vId)) {
      selectionSet.delete(vId);
    } else {
      selectionSet.add(vId);
    }
    selectActive = true;

    if (selectionSet.size === studentData.length) {
      document.getElementById("flexCheckDefault").indeterminate = false;
    } else if (selectionSet.size === 0) {
      document.getElementById("flexCheckDefault").indeterminate = true;
    } else {
      document.getElementById("flexCheckDefault").indeterminate = true;
    }
  }

  function selectNone() {
    selectionSet.clear();
    selectActive = false;
    document.getElementById("flexCheckDefault").indeterminate = false;
  }

  function selectAll() {
    selectionSet = new Set(studentData.map((s) => s.id));
    selectActive = true;
    document.getElementById("flexCheckDefault").indeterminate = false;
  }

  let okCount = 0;
  let errorCount = 0;

  async function massDelete() {
    console.log("111", selectionSet);
    if (
      confirm(
        $_("COURSE.REMOVE_ALL_SELECTED_STUDENTS", {
          values: { count: selectionSet.size },
        }),
      )
    ) {
      // Save it!
      studentDataStatus = "loading";
      okCount = 0;
      errorCount = 0;

      let parts = [...selectionSet];
      for (let i = 0; i < parts.length; i++) {
        let e = parts[i];

        await deleteData(
          "api/courses/" +
            courseId +
            "/student-accounts/" +
            e,
        )
          .then((data) => {
            okCount++;
          })
          .catch((error) => {
            errorCount++;
            studentDataStatus = "error";
            console.log("error remove-all ", error);
          });
      }

      loadStudents();
      studentDataStatus = "loading";

      console.log("remove-all summary", okCount, errorCount);
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  }
</script>

<DialogInviteStudentsToClassByCode
  bind:this={dialogInviteStudentsToClassByCode}
  {courseId}
  courseInviteCode={"ABC"}
/>

<div class="card shadow">
  {#if $userConfig?.serverFlags?.studentManagement === "R"}
    <div class="card-header py-3">{$_("STUDENTS_MANAGED_BY_ADMIN_ONLY")}</div>
  {:else}
    <div class="card-header py-3">
      <div
        class="no-print d-flex flex-row align-items-center justify-content-between"
      >
        {#if !addStudentFlag && !uploadStudentCsvFlag && !addStudentRosterFlag && !createStudentRosterFlag}
          <div>
            {#if !$userDetails?.dsns && !$userDetails?.spRefreshToken && !$userConfig?.serverFlags?.useOnlyRosters}<button
                class="btn btn-primary s-button-primary s-mr-10px"
                disabled={"RW" !== $currentCourseRoleCheck?.permissions}
                on:click={addNewStudent}
              >
                <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
                  "INVITE_STUDENT",
                )}<br />
              </button>
              <button
                class="btn btn-primary s-button-primary s-mr-10px"
                disabled={"RW" !== $currentCourseRoleCheck?.permissions}
                on:click={uploadStudentCsv}
              >
                <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
                  "INVITE_STUDENT_CSV",
                )}<br />
              </button>
            {/if}
            {#if $userDetails?.dsns || $userDetails?.spRefreshToken || $userConfig?.serverFlags?.adminStudentRoster === true || $userConfig?.serverFlags?.studentManagement === "RW"}
              <button
                class="btn btn-primary s-button-primary s-mr-10px"
                disabled={"RW" !== $currentCourseRoleCheck?.permissions}
                on:click={addStudentRoster}
              >
                <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
                  "STUDENT_ROSTER.ADD_FROM_ROSTER",
                )}<br />
              </button>
              {#if !$userDetails?.dsns && !$userConfig?.serverFlags?.useOnlyRosters}
                <button
                  class="btn btn-primary s-button-primary s-mr-10px"
                  on:click={createStudentRoster}
                >
                  <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
                    "STUDENT_ROSTER.CREATE_FROM_COURSE",
                  )}<br />
                </button>
              {/if}
            {/if}
            {#if !$userConfig?.serverFlags?.useOnlyRosters && $userConfig?.serverFlags?.inviteStudentsByQrCodeToClass}
              <button
                class="btn btn-primary s-button-primary s-mr-10px"
                on:click={() => dialogInviteStudentsToClassByCode.openDialog()}
                ><i class="fa fa-plus" style="padding-right: 8px;" />{$_(
                  "COURSE.INVITE_BY_CODE",
                )}</button
              >
            {/if}
            {#if selectActive}
              <button
                class="btn btn-danger s-button-danger me-2"
                on:click={massDelete}>⌦ {$_("COURSE.MASS_DELETE_STUDENTS")}</button
              >
            {/if}
          </div>

          <StudentEnrollmentDropdown
            elementId="StudentAccountDropDown"
            on:exportToCsv={exportToCsv}
            on:refresh={loadStudents}
          />
        {/if}
      </div>

      {#if addStudentFlag}
        <div>
          <div class="form-row">
            <div class="col-lg-12 offset-lg-0">
              <label
                for="courseEditEmail"
                style="padding-right: 12px;padding-top: 6px;"
                >{$_("STUDENT_EMAIL")}</label
              >
              <input
                id="courseEditEmail"
                class="form-control"
                class:is-invalid={!isValidEmail}
                type="text"
                placeholder={$_("STUDENT_EMAIL")}
                bind:value={newStudent.studentEmail}
                on:keydown={skipEnterOnInput}
              />
              <div class="invalid-feedback">
                {$_("EMAIL_ADDRESS_INVALID_FEEDBACK")}
              </div>
            </div>
          </div>

          <div class="form-row mt-3">
            <button
              class="btn btn-secondary s-button-secondary s-mr-10px"
              on:click={cancelNewStudentEdit}
            >
              <i class="far fa-window-close" style="padding-right: 8px;" />{$_(
                "CANCEL",
              )}
            </button>

            <button
              class="btn btn-primary s-button-primary"
              disabled={studentDataStatus === "saving" ||
                newStudent.studentEmail === ""}
              on:click|preventDefault={(x) => {
                addNewStudentSave();
              }}
            >
              <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                "INVITE_STUDENT",
              )}
              <br />
            </button>
          </div>
        </div>
      {/if}

      {#if uploadStudentCsvFlag}
        <div>
          <div
            bind:this={dropArea}
            id="drop-area"
            on:handleDrop={handleDrop}
            on:dragleave|preventDefault={unhighlight}
            on:drop|preventDefault={handleDrop}
            on:dragenter|preventDefault={highlight}
            on:dragover|preventDefault={highlight}
            class:highlight={dropAreaHighlighted}
          >
            <form class="my-form">
              <p>
                {$_("UPLOAD_CSV_STUDENTS_INSTRUCTIONS")}
                {$_("UPLOAD_CSV_STUDENTS_INSTRUCTIONS_SAMPLE")}<a
                  href="assets/csv/SensayStudentAccountCSVImportSample.csv"
                  target="_blank"
                  download
                  >{$_("UPLOAD_CSV_STUDENTS_INSTRUCTIONS_SAMPLE_DOWNLOAD")}</a
                >
              </p>
              <input
                type="file"
                id="fileElem"
                accept="text/*"
                on:change={(e) => {
                  handleFiles(e.target.files);
                }}
              />
              <label class="button" for="fileElem"
                >{$_("SELECT_CSV_FILE")}</label
              >
            </form>
            <div id="gallery" />
            <div>
              <table class="table table-hover">
                <thead
                  ><th>{$_("STUDENT_EMAIL")}</th><th>{$_("VALID_EMAIL")}</th
                  ></thead
                >
                <tbody>
                  {#each csvTable as r, i}
                    {#if r && (r?.length === 1 || r?.length === 3)}
                      <tr>
                        <td>{r[0]}</td>
                        <td
                          >{#if validateEmail(r[0])}<i
                              class="fas fa-check"
                            />{:else}<i class="fas fa-times" />{/if}</td
                        >
                      </tr>
                    {/if}
                  {/each}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="form-row mt-3">
          <button
            class="btn btn-secondary s-button-secondary s-mr-10px"
            on:click={cancelNewStudentEdit}
          >
            <i class="far fa-window-close" style="padding-right: 8px;" />{$_(
              "CANCEL",
            )}
          </button>

          <button
            class="btn btn-primary s-button-primary"
            disabled={studentDataStatus === "saving" || csvTable?.length === 0}
            on:click|preventDefault={(x) => {
              addNewStudentUploadCsv();
            }}
          >
            <i class="fa fa-save" style="padding-right: 8px;" />{$_(
              "INVITE_STUDENT",
            )}
            <br />
          </button>
        </div>
      {/if}

      {#if addStudentRosterFlag}
        <StudentAccountAddFromRoster
          courseId={$currentCourseId}
          on:studentImportDone={loadStudents}
          on:cancelStudentRoster={cancelStudentRoster}
        />
      {/if}

      {#if createStudentRosterFlag}
        <StudentRosterFromCourse
          courseId={$currentCourseId}
          on:importDone={cancelCreateStudentRoster}
          on:cancelCreateStudentRoster={cancelCreateStudentRoster}
        ></StudentRosterFromCourse>
      {/if}

      {#if importStatus === "error"}
        <div>
          <div class="alert alert-danger">
            {#if importStatusMessage === "STUDENT_ACCOUNT_LIMIT_REACHED"}
              {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_LIMIT_REACHED")}
            {:else if importStatusMessage === "STUDENT_ACCOUNT_ALREADY_ADDED"}
              {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_ALREADY_ADDED")}
            {:else}
              {$_("STUDENT_ACCOUNT.IMPORT_ERROR")}
            {/if}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              type="button"
              class="close"
              on:click={() => {
                importStatus = undefined;
              }}
            >
              <span>&times;</span>
            </span>
          </div>
        </div>
      {:else if importStatus === "ok"}
        <div>
          <div class="alert alert-success">
            {$_("STUDENT_ACCOUNT.IMPORT_SUCCESS")}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              type="button"
              class="close"
              on:click={() => {
                importStatus = undefined;
              }}
            >
              <span>&times;</span>
            </span>
          </div>
        </div>
      {/if}
    </div>
  {/if}

  <div class="card-group">
    {#if studentDataStatus === "loading"}
      <Loader />
    {:else if studentDataStatus === "error"}
      {$_("ERROR_LOADING_STUDENT_ACCOUNT_LIST")}
    {:else}
      <div class="card-body">
        <div>
          {#if !arrayIsEmpty(studentData)}
            <!-- <div>
            {$_("STUDENT_COUNT")}:{studentData?.length} / 30
          </div> -->
            <div
              class="table-responsive table mt-2"
              id="dataTable"
              role="grid"
              aria-describedby="dataTable_info"
            >
              <table class="table table-hover" id="dataTable">
                <thead>
                  <tr>
                    <th>
                      <div class="btn-group">
                        <button type="button" class="btn btn-light">
                          <input
                            on:click={changeSelectCheckbox}
                            class="form-check-input"
                            type="checkbox"
                            bind:checked={selectActive}
                            id="flexCheckDefault"
                          />
                        </button><button
                          type="button"
                          class="btn btn-light dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a on:click={selectNone} class="dropdown-item"
                              >{$_("CLEAR")}</a
                            >
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li>
                            <a class="dropdown-item" on:click={selectAll}
                              >{$_("SELECT_ALL")}</a
                            >
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th
                      >{$_("STUDENT_NAME")}<span
                        on:click={() => changeSort("fullName", 1)}
                        ><i class="fas fa-caret-up ms-1 mr-1" /></span
                      >
                      <span on:click={() => changeSort("fullName", -1)}
                        ><i class="fas fa-caret-down mr-1" /></span
                      ></th
                    >
                    <th
                      >{$_("EMAIL_ADDRESS")}<span
                        on:click={() => changeSort("email", 1)}
                        ><i class="fas fa-caret-up ms-1 mr-1" /></span
                      >
                      <span on:click={() => changeSort("email", -1)}
                        ><i class="fas fa-caret-down mr-1" /></span
                      ></th
                    >
                    <th
                      >{$_("STATUS")}<span
                        on:click={() => changeSort("state", 1)}
                        ><i class="fas fa-caret-up ms-1 mr-1" /></span
                      >
                      <span on:click={() => changeSort("state", -1)}
                        ><i class="fas fa-caret-down mr-1" /></span
                      ></th
                    >
                    <th>{$_("ACTIONS")}</th>
                  </tr>
                </thead>
                <tbody>
                  {#if studentData}
                    {#key studentData}
                      {#each studentData as l, i}
                        <tr class="lessonRow">
                          <th scope="row"
                            >{#if selectActive}
                              <input
                                class="form-check-input me-2"
                                type="checkbox"
                                checked={selectionSet.has(l.id)}
                                on:click={() => changeSelect(l.id)}
                              />
                            {/if}{i + 1}
                          </th>
                          <td
                            >{#if l?.studentFirstName || l?.studentLastName}{l?.studentFirstName}
                              {l?.studentLastName}{/if}</td
                          >
                          <td
                            >{#if l?.state === "PENDING"}{l?.pendingInvitationEmail}{:else}
                              {#if l?.studentId}<a
                                  href="/#/student-account-detail/{l?.studentId}"
                                  >{l?.studentLogin}</a
                                >{/if}{/if}</td
                          >

                          <td>
                            {#if l?.state === "PENDING"}<i
                                class="fas fa-user-clock me-2"
                              />{:else if l?.state === "ACTIVE"}
                              <i class="fas fa-user-check me-2" />{/if}{$_(
                              `ORGANIZATION.${l?.state}`,
                            )}

                            {#if l?.studentAccount?.state}{l?.studentAccount
                                ?.state}{/if}</td
                          >
                          <td
                            >{#if $userConfig?.serverFlags?.studentManagement !== "R"}<button
                                class="btn btn-danger s-button-danger"
                                disabled={selectActive}
                                title={$_("DELETE")}
                                on:click={(x) => {
                                  removeStudent(
                                    l?.id,
                                    l?.state === "PENDING"
                                      ? l?.pendingInvitationEmail
                                      : l?.studentAccount?.login,
                                  );
                                }}>⌦</button
                              >{/if}</td
                          >
                        </tr>
                      {/each}
                    {/key}
                  {:else}No data{/if}
                </tbody>
                <tfoot>
                  <tr />
                </tfoot>
              </table>
            </div>
          {:else}
            <div class="row">
              <svg
                width="201"
                height="200"
                viewBox="0 0 201 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M56.1397 135.23C53.7276 135.232 51.369 134.519 49.3621 133.181C47.3552 131.843 45.7899 129.94 44.8641 127.713C43.9383 125.485 43.6935 123.034 44.1607 120.667C44.6279 118.301 45.7861 116.126 47.4889 114.418C49.1917 112.709 51.3627 111.544 53.7275 111.069C56.0924 110.594 58.545 110.831 60.7753 111.749C63.0057 112.668 64.9137 114.227 66.2583 116.229C67.6029 118.232 68.3237 120.588 68.3297 123C68.3244 126.235 67.0397 129.337 64.7559 131.628C62.4721 133.919 59.3747 135.214 56.1397 135.23Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M76.4997 163.69H35.8097V158.27C35.7496 155.563 36.231 152.871 37.2254 150.353C38.2199 147.834 39.7074 145.54 41.6007 143.604C43.494 141.668 45.7549 140.13 48.2507 139.08C50.7465 138.03 53.427 137.489 56.1347 137.489C58.8424 137.489 61.5228 138.03 64.0187 139.08C66.5145 140.13 68.7754 141.668 70.6687 143.604C72.562 145.54 74.0495 147.834 75.044 150.353C76.0384 152.871 76.5197 155.563 76.4597 158.27L76.4997 163.69Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M99.4998 135.23C97.0881 135.23 94.7306 134.515 92.7249 133.176C90.7193 131.837 89.1556 129.933 88.2313 127.706C87.3071 125.478 87.0637 123.027 87.532 120.661C88.0004 118.295 89.1593 116.121 90.8625 114.414C92.5657 112.707 94.7367 111.542 97.1013 111.068C99.4659 110.594 101.918 110.831 104.148 111.75C106.378 112.669 108.285 114.228 109.629 116.23C110.973 118.233 111.694 120.588 111.7 123C111.695 126.237 110.408 129.34 108.122 131.632C105.837 133.923 102.737 135.217 99.4998 135.23Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M119.83 163.69H79.1699V158.27C79.1699 152.879 81.3118 147.708 85.1244 143.895C88.9371 140.082 94.1081 137.94 99.4999 137.94C104.892 137.94 110.063 140.082 113.875 143.895C117.688 147.708 119.83 152.879 119.83 158.27V163.69Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M142.86 135.23C140.448 135.23 138.091 134.515 136.085 133.176C134.08 131.837 132.516 129.933 131.592 127.706C130.667 125.478 130.424 123.027 130.892 120.661C131.361 118.295 132.52 116.121 134.223 114.414C135.926 112.707 138.097 111.542 140.462 111.068C142.826 110.594 145.278 110.831 147.508 111.75C149.738 112.669 151.645 114.228 152.989 116.23C154.334 118.233 155.054 120.588 155.06 123C155.057 126.238 153.772 129.342 151.486 131.634C149.199 133.927 146.098 135.219 142.86 135.23Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M163.19 163.69H122.5V158.27C122.44 155.563 122.921 152.871 123.916 150.353C124.91 147.834 126.398 145.54 128.291 143.604C130.184 141.668 132.445 140.13 134.941 139.08C137.437 138.03 140.117 137.489 142.825 137.489C145.533 137.489 148.213 138.03 150.709 139.08C153.205 140.13 155.466 141.668 157.359 143.604C159.252 145.54 160.74 147.834 161.734 150.353C162.729 152.871 163.21 155.563 163.15 158.27L163.19 163.69Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M104.21 71.5397C101.796 71.5397 99.4373 70.8239 97.4308 69.4829C95.4243 68.1419 93.8606 66.2359 92.9375 64.0061C92.0144 61.7762 91.7734 59.3227 92.2449 56.9559C92.7165 54.589 93.8794 52.4152 95.5866 50.7094C97.2938 49.0036 99.4686 47.8425 101.836 47.3729C104.203 46.9032 106.656 47.1463 108.885 48.0712C111.115 48.9961 113.019 50.5614 114.359 52.569C115.698 54.5766 116.412 56.9363 116.41 59.3497C116.394 62.5795 115.103 65.6724 112.818 67.9554C110.534 70.2383 107.44 71.5265 104.21 71.5397ZM104.21 52.5397C102.869 52.5397 101.558 52.9373 100.443 53.6823C99.328 54.4273 98.459 55.4862 97.9459 56.7251C97.4327 57.9639 97.2984 59.3272 97.56 60.6424C97.8216 61.9576 98.4674 63.1656 99.4156 64.1138C100.364 65.062 101.572 65.7078 102.887 65.9694C104.202 66.231 105.565 66.0967 106.804 65.5836C108.043 65.0704 109.102 64.2014 109.847 63.0864C110.592 61.9715 110.99 60.6606 110.99 59.3197C110.974 57.5291 110.254 55.8169 108.985 54.5535C107.716 53.2902 106 52.5775 104.21 52.5697V52.5397Z"
                  fill="#707070"
                />
                <path
                  d="M58.1397 135.23C55.7276 135.232 53.369 134.519 51.3621 133.181C49.3552 131.843 47.7899 129.94 46.8641 127.713C45.9383 125.485 45.6935 123.034 46.1607 120.667C46.6279 118.301 47.7861 116.126 49.4889 114.418C51.1917 112.709 53.3627 111.544 55.7275 111.069C58.0924 110.594 60.545 110.831 62.7753 111.749C65.0057 112.668 66.9137 114.227 68.2583 116.229C69.6029 118.232 70.3237 120.588 70.3297 123C70.3244 126.235 69.0397 129.337 66.7559 131.628C64.4721 133.919 61.3747 135.214 58.1397 135.23ZM58.1397 116.23C56.7978 116.228 55.4856 116.624 54.3691 117.369C53.2526 118.113 52.3821 119.172 51.8677 120.411C51.3533 121.65 51.2182 123.014 51.4795 124.33C51.7407 125.646 52.3867 126.855 53.3355 127.804C54.2843 128.753 55.4933 129.399 56.8094 129.66C58.1255 129.922 59.4896 129.786 60.7289 129.272C61.9682 128.758 63.0269 127.887 63.7712 126.771C64.5154 125.654 64.9116 124.342 64.9097 123C64.8913 121.213 64.1712 119.505 62.9047 118.244C61.6382 116.983 59.9268 116.27 58.1397 116.26V116.23Z"
                  fill="#707070"
                />
                <path
                  d="M78.4997 163.69H37.8097V158.27C37.7496 155.563 38.231 152.871 39.2254 150.353C40.2199 147.834 41.7074 145.54 43.6007 143.604C45.494 141.668 47.7549 140.13 50.2507 139.08C52.7465 138.03 55.427 137.489 58.1347 137.489C60.8424 137.489 63.5228 138.03 66.0187 139.08C68.5145 140.13 70.7754 141.668 72.6687 143.604C74.562 145.54 76.0495 147.834 77.044 150.353C78.0384 152.871 78.5197 155.563 78.4597 158.27L78.4997 163.69ZM43.2697 158.27H73.0397C73.0912 156.28 72.7438 154.3 72.018 152.447C71.2921 150.593 70.2024 148.904 68.8133 147.478C67.4241 146.053 65.7636 144.92 63.9296 144.146C62.0955 143.373 60.1252 142.974 58.1347 142.974C56.1442 142.974 54.1738 143.373 52.3398 144.146C50.5058 144.92 48.8452 146.053 47.4561 147.478C46.0669 148.904 44.9773 150.593 44.2514 152.447C43.5255 154.3 43.1781 156.28 43.2297 158.27H43.2697Z"
                  fill="#707070"
                />
                <path
                  d="M101.5 135.23C99.0881 135.23 96.7306 134.515 94.7249 133.176C92.7193 131.837 91.1556 129.933 90.2313 127.706C89.3071 125.478 89.0637 123.027 89.532 120.661C90.0004 118.295 91.1593 116.121 92.8625 114.414C94.5657 112.707 96.7367 111.542 99.1013 111.068C101.466 110.594 103.918 110.831 106.148 111.75C108.378 112.669 110.285 114.228 111.629 116.23C112.973 118.233 113.694 120.588 113.7 123C113.695 126.237 112.408 129.34 110.122 131.632C107.837 133.923 104.737 135.217 101.5 135.23ZM101.5 116.23C100.159 116.23 98.848 116.628 97.733 117.373C96.618 118.118 95.749 119.177 95.2359 120.415C94.7227 121.654 94.5884 123.018 94.85 124.333C95.1117 125.648 95.7574 126.856 96.7056 127.804C97.6538 128.752 98.8619 129.398 100.177 129.66C101.492 129.921 102.855 129.787 104.094 129.274C105.333 128.761 106.392 127.892 107.137 126.777C107.882 125.662 108.28 124.351 108.28 123.01C108.264 121.22 107.544 119.507 106.275 118.244C105.006 116.981 103.29 116.268 101.5 116.26V116.23Z"
                  fill="#707070"
                />
                <path
                  d="M121.83 163.69H81.1699V158.27C81.1699 155.601 81.6958 152.957 82.7175 150.49C83.7391 148.024 85.2366 145.783 87.1244 143.895C89.0123 142.007 91.2534 140.51 93.72 139.488C96.1865 138.466 98.8302 137.94 101.5 137.94C104.17 137.94 106.813 138.466 109.28 139.488C111.746 140.51 113.988 142.007 115.875 143.895C117.763 145.783 119.261 148.024 120.282 150.49C121.304 152.957 121.83 155.601 121.83 158.27V163.69ZM86.5899 158.27H116.41C116.41 154.316 114.839 150.524 112.043 147.727C109.247 144.931 105.454 143.36 101.5 143.36C97.5455 143.36 93.7531 144.931 90.957 147.727C88.1608 150.524 86.5899 154.316 86.5899 158.27Z"
                  fill="#707070"
                />
                <path
                  d="M144.86 135.23C142.448 135.23 140.091 134.515 138.085 133.176C136.08 131.837 134.516 129.933 133.592 127.706C132.667 125.478 132.424 123.027 132.892 120.661C133.361 118.295 134.52 116.121 136.223 114.414C137.926 112.707 140.097 111.542 142.462 111.068C144.826 110.594 147.278 110.831 149.508 111.75C151.738 112.669 153.645 114.228 154.989 116.23C156.334 118.233 157.054 120.588 157.06 123C157.057 126.238 155.772 129.342 153.486 131.634C151.199 133.927 148.098 135.219 144.86 135.23ZM144.86 116.23C143.519 116.23 142.208 116.628 141.093 117.373C139.978 118.118 139.109 119.177 138.596 120.415C138.083 121.654 137.949 123.018 138.21 124.333C138.472 125.648 139.118 126.856 140.066 127.804C141.014 128.752 142.222 129.398 143.537 129.66C144.853 129.921 146.216 129.787 147.455 129.274C148.694 128.761 149.752 127.892 150.497 126.777C151.242 125.662 151.64 124.351 151.64 123.01C151.624 121.22 150.904 119.507 149.635 118.244C148.366 116.981 146.651 116.268 144.86 116.26V116.23Z"
                  fill="#707070"
                />
                <path
                  d="M165.19 163.69H124.5V158.27C124.44 155.563 124.921 152.871 125.916 150.353C126.91 147.834 128.398 145.54 130.291 143.604C132.184 141.668 134.445 140.13 136.941 139.08C139.437 138.03 142.117 137.489 144.825 137.489C147.533 137.489 150.213 138.03 152.709 139.08C155.205 140.13 157.466 141.668 159.359 143.604C161.252 145.54 162.74 147.834 163.734 150.353C164.729 152.871 165.21 155.563 165.15 158.27L165.19 163.69ZM129.96 158.27H159.77C159.822 156.28 159.474 154.3 158.748 152.447C158.023 150.593 156.933 148.904 155.544 147.478C154.155 146.053 152.494 144.92 150.66 144.146C148.826 143.373 146.856 142.974 144.865 142.974C142.875 142.974 140.904 143.373 139.07 144.146C137.236 144.92 135.576 146.053 134.187 147.478C132.797 148.904 131.708 150.593 130.982 152.447C130.256 154.3 129.909 156.28 129.96 158.27Z"
                  fill="#707070"
                />
                <path
                  d="M119.12 94L147.33 59L136.33 50.21L115.13 75.61H89.3104C86.7985 75.6205 84.3923 76.6223 82.6151 78.3976C80.838 80.1729 79.8336 82.578 79.8204 85.09V95.93H71.6904V101.35H77.1104V128.46H82.5004V101.35H120.5V128.46H125.92V101.35H131.34V95.93H119.12V94ZM94.7204 96V89.16H89.3004V95.93H85.2404V85.09C85.2378 84.0096 85.6637 82.9723 86.4248 82.2056C87.1859 81.4388 88.2201 81.0053 89.3004 81H117.68L137.11 57.68L139.69 59.74L113.7 92V95.89L94.7204 96Z"
                  fill="#707070"
                />
                <path
                  d="M157.06 101.36H144.86V95.9304H151.64V41.7304H51.3604V95.9304H58.1404V101.36H45.9404V36.3604H157.06V101.36Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M60.8496 51.2197H79.8496V56.6397H60.8496V51.2197Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M60.8496 64.7695H73.0396V70.1895H60.8496V64.7695Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M60.8496 78.3203H73.0396V83.7403H60.8496V78.3203Z"
                  fill="#707070"
                />
              </svg>

              <h3 class="text-center">
                {$_("STUDENT_ACCOUNT_TABLE_EMPTY_PROMPT_EMPTY")}
              </h3>
              <p style="padding-left:15%; padding-right:15%;">
                {$_("STUDENT_ACCOUNT_TABLE_EMPTY_PROMPT_NEW")}
              </p>
              <p style="padding-left:15%; padding-right:15%;">
                {$_("STUDENT_ACCOUNT_TABLE_EMPTY_PROMPT_EXISTING")}
                (<a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://learn.oksensay.com"
                  >https://learn.oksensay.com</a
                >)
              </p>
            </div>
            <div class="center">
              <button class="btn btn-info s-button-info" on:click={openFeedback}
                >{$_("HAVE_A_QUESTION")}</button
              >
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  #drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 480px;
    margin: 50px auto;
    padding: 20px;
  }
  #drop-area.highlight {
    border-color: purple;
  }
  p {
    margin-top: 0;
  }
  .my-form {
    margin-bottom: 10px;
  }
  #gallery {
    margin-top: 10px;
  }
  .button {
    display: inline-block;
    padding: 10px;
    background: #ccc;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .button:hover {
    background: #ddd;
  }
  #fileElem {
    display: none;
  }
  .close {
    padding-left: 0.75rem;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
